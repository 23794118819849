<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>
    <div id="app-content">
      <div class="form-box">
        <van-form colon>
          <van-field readonly clickable :value="formData.city" label="缴费地区" placeholder="点击选择地区"
            @click="showArea = true" />
          <van-field readonly clickable :value="formData.acctFlag | acctFlagFilter" label="缴费单位" placeholder="点击选择地区"
            @click="showAcctFlag = true" />
          <van-field v-model="formData.acctNo" label="缴费户号" placeholder="请填写缴费户号"
            :rules="[{ required: true, message: '请填写缴费户号' }]" />
          <van-field v-model="formData.idcard" label="身份证后6位" placeholder="请输入身份证后6位"
            :rules="[{ required: true, message: '请输入身份证后6位' }]" />
          <van-field name="switch" label="是否默认">
            <template #input>
              <van-switch v-model="formData.isDefault" active-color="#9E7BFF" size="16" active-value="Y"
                inactive-value="N" />
            </template>
          </van-field>
        </van-form>
      </div>
      <div class="submitBut" @click="submit()">保存</div>
    </div>

    <!-- 地区选择 -->
    <van-popup v-model="showArea" position="bottom">
      <van-area :area-list="areaList" :columns-num="2" @confirm="onConfirm" @cancel="showArea = false" />
    </van-popup>

    <!-- 国网电网选择 -->
    <van-action-sheet v-model="showAcctFlag" :actions="acctFlagOption" @select="onSelect" />
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { mallApi } from '@/api'
import { mapState, mapActions } from 'vuex'



const acctFlagMap = Object.freeze([
  { name: '国家电网', value: 'SGCC' },
  { name: '南方电网', value: 'CSG' }]
)

export default {
  name: 'editDoorNo',
  data() {
    return {
      username: '',
      formData: {
        province: '',
        city: '',
        acctFlag: 'SGCC',
        acctNo: '',
        idcard: '',
        isDefault: 'Y'
      },
      showArea: false,
      areaList: areaList,
      showAcctFlag: false,
      acctFlagOption: acctFlagMap
    }
  },
  computed: {
    ...mapState('electricity', ['defaultDoor', 'editDoorData']),
  },
  filters: {
    acctFlagFilter(val) {
      let item = acctFlagMap.find(v => v.value === val)
      return item ? item.name : val
    }
  },
  created() {
    if (Object.keys(this.editDoorData).length <= 0) {
      this.goPage('/doorList')
      return
    }

    this.formData = { ...this.editDoorData }
  },
  methods: {
    // 保存
    submit() {
      let params = {
        trxCode: 'XF333',
      }
      params.custAcct = this.formData
      params.custAcct.acctType = 'ELECTRIC'
      // console.log(params);
      mallApi(params).then(res => {
        const { rspCode, rspMsg, data } = res
        if (rspCode === '0000') {
          if (this.defaultDoor.id === this.formData.id) {
            this.$store.commit('electricity/SET_DEFAULTDOOR', this.formData)
          }
          this.$router.replace({ path: '/doorList' })
          this.$toast('添加成功')
          this.loading = false
        } else {
          this.$toast(rspMsg)
        }
      })
    },

    // 选择地区
    onConfirm(e) {
      this.formData.city = e[1].name
      this.formData.province = e[0].name
      this.showArea = false
      // console.log(e);
    },

    // 选择缴纳单位
    onSelect(e) {
      this.formData.acctFlag = e.value
      this.showAcctFlag = false
    },

    // 页面跳转
    goPage(path) {
      this.$router.push({ path: path })
    },

  }
}
</script>

<style lang='less' scoped>
.form-box {
  margin: 12px;
  border-radius: 10px;
  padding-left: 8px;
  background: #fff;
  overflow: hidden;
}
.van-cell {
  padding: 3vw 4vw;
}
.van-cell::after {
  border: none;
}
/deep/ .van-cell__title {
  font-weight: bold;
  font-size: 3.8vw;
  color: #333;
}
</style>